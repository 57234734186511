/*
  Check that a media object has optimized formats of different qualities
 */
export function hasMediaOptimizedFormats<
  T extends {
    formats?: unknown;
    provider?: string;
    url?: string;
  },
>(
  media: T,
): media is T & {
  formats: Record<"large" | "medium" | "small" | "thumbnail", { url: string }>;
} {
  if (
    media.formats === null ||
    typeof media.formats !== "object" ||
    !("formats" in media)
  ) {
    return false;
  }

  const formats: any = media.formats;

  //   check that formats object has the required keys with url value
  for (const key of ["large", "medium", "small", "thumbnail"] as const) {
    if (
      typeof formats[key] !== "object" ||
      formats[key] === null ||
      typeof formats[key].url !== "string"
    ) {
      return false;
    }
  }

  return true;
}
