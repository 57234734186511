import createClient from "openapi-fetch";
import type { paths } from "~/openapi/schema";
import { isServer } from "~/lib/isServer";
import { hasMediaOptimizedFormats } from "~/openapi/hasMediaOptimizedFormats";

class StrapiRepository {
  private readonly strapiClient;

  constructor() {
    const strapiUrl = isServer
      ? process.env.STRAPI_INTERNAL_URL
      : window.ENV.STRAPI_PUBLIC_URL;
    if (!strapiUrl) {
      throw new Error("Strapi url is not defined");
    }
    this.strapiClient = createClient<paths>({
      baseUrl: `${strapiUrl}/api`,
    });
  }

  private getMediaUrl = (filePath?: string, provider?: string) => {
    if (!filePath) {
      return "";
    }

    if (provider === "local") {
      const strapiPublicUrl = isServer
        ? process.env.STRAPI_PUBLIC_URL
        : window.ENV.STRAPI_PUBLIC_URL;

      return `${strapiPublicUrl}${filePath}`;
    }

    return filePath;
  };

  getImageUrl = (
    image: Parameters<typeof hasMediaOptimizedFormats>[0],
    preferredFormat: "large" | "medium" | "small" | "thumbnail" = "large",
  ) => {
    const hasOptimizedFormats = hasMediaOptimizedFormats(image);

    if (hasOptimizedFormats) {
      return this.getMediaUrl(
        image.formats[preferredFormat].url,
        image.provider,
      );
    }

    return this.getMediaUrl(image.url, image.provider);
  };

  getNavbarItems = async () => {
    return this.strapiClient.GET("/navbar", {
      params: {
        query: {
          // @ts-ignore
          pLevel: 3,
        },
      },
    });
  };

  getPageBySlug = async (pageSlug: string) => {
    return this.strapiClient.GET("/pages/slug/{slug}", {
      params: {
        path: {
          slug: pageSlug,
        },
        // @ts-ignore
        query: {
          pLevel: 4,
        },
      },
    });
  };

  getCategoryWithReferences = async () => {
    return this.strapiClient.GET("/categories-with-references");
  };

  getReferences = async (query?: Record<string, string | number | boolean>) => {
    console.log("getReferences query", query);
    return this.strapiClient.GET("/references", {
      params: {
        query: {
          ...query,
          // @ts-ignore
          pLevel: 2,
        },
      },
    });
  };

  getHomepage = async () => {
    return this.strapiClient.GET("/homepage", {
      params: {
        query: {
          // @ts-ignore
          "populate[hero_photos][populate]": "*",
          "populate[services][populate]": "*",
          "populate[contacts][populate]": "*",
          "populate[social_links][populate]": "*",
        },
      },
    });
  };

  getCategoryBySlug = async (categorySlug: string) => {
    return this.strapiClient.GET("/categories/slug/{slug}", {
      params: {
        path: {
          slug: categorySlug,
        },
      },
    });
  };

  getReferenceBySlug = async (referenceSlug: string) => {
    return this.strapiClient.GET("/references/slug/{slug}", {
      params: {
        path: {
          slug: referenceSlug,
        },
        // @ts-ignore
        query: {
          pLevel: 2,
        },
      },
    });
  };

  getContacts = async () => {
    return this.strapiClient.GET("/contact-page", {
      params: {
        query: {
          // @ts-ignore
          pLevel: 4,
        },
      },
    });
  };

  getServices = async () => {
    return this.strapiClient.GET("/servicepage", {
      params: {
        query: {
          // @ts-ignore
          pLevel: 4,
        },
      },
    });
  };
}

export const strapiRepository = new StrapiRepository();
